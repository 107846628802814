import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/iconChat",
    name: "iconChat",
    component: () => import("@/views/still/chat/iconChat.vue"),
  },
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-in/:loginpassword",
        name: "sign-in-direct",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-in/:loginpassword/:redirect",
        name: "sign-in-redirect",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-in/:loginpassword/:redirect/:otherparam",
        name: "sign-in-redirect-other-param",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("@/views/still/chat/chat.vue"),
  },
  {
    path: "/alln2f/:otherparam?",
    name: "alln2f",
    component: () => import("@/views/n2f/all.vue"),
  },
  {
    path: "/viewN2f/:id?",
    name: "viewN2f",
    component: () => import("@/views/n2f/viewN2f.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },

  {
    path: "/",
    redirect: "/crafted/pages/profile/overview",
    name:'parentstillboard',
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/profil",
        name: "profil",
        component: () => import("@/views/still/Profil.vue"),
      },
      {
        path: "/crafted/pages/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        meta: {
          breadcrumbs: ["Pages", "Profile"],
        },
        children: [
          {
            path: "loader/:mail",
            name: "loader",
            component: () =>
              import("@/views/crafted/pages/Loader.vue"),
            meta: {
              pageTitle: "Overview",
            },
          },
          {
            path: "loader/:otherparam",
            name: "loader",
            component: () =>
              import("@/views/crafted/pages/Loader.vue"),
            meta: {
              pageTitle: "Overview",
            },
          },
          {
            path: "changeUser/:mail",
            name: "change-user-mail",
            component: () =>
              import("@/views/general/General.vue"),
            meta: {
              pageTitle: "Overview",
            },
          },
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/general/General.vue"),
            meta: {
              pageTitle: "Overview",
            },
          },
          {
            path: "list_n2f",
            name: "list_n2f",
            component: () =>
              import("@/views/n2f/N2fList.vue"),
            meta: {
              pageTitle: "Note de frais",
            },
          },          
          {
            path: "projects",
            name: "profile-projects",
            component: () =>
              import("@/views/crafted/pages/profile/Projects.vue"),
            meta: {
              pageTitle: "Projects",
            },
          },
          {
            path: "indispos",
            name: "indispos",
            component: () =>
              import("@/views/indispos/Indispos.vue"),
            meta: {
              pageTitle: "Indispos",
            },
          },
          {
            path: "indispos/:all?",
            name: "indisposall",
            component: () =>
              import("@/views/indispos/Indispos.vue"),
            meta: {
              pageTitle: "Indispos",
            },
          },
          {
            path: "documents",
            name: "documents",
            component: () =>
              import("@/views/GED/GED.vue"),
            meta: {
              pageTitle: "Documents",
            },
          },
          {
            path: "commissions",
            name: "commissions",
            component: () =>
              import("@/views/commissions/Commissions.vue"),
            meta: {
              pageTitle: "Commissions",
            },
          },
          {
            path: "me",
            name: "me",
            component: () =>
              import("@/views/general/General.vue"),
            meta: {
              pageTitle: "me",
            },
          },
        ],
      },
    ],
  },

];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {

  await store.commit(Mutations.RESET_LAYOUT_CONFIG);
  await store.dispatch(Actions.VERIFY_AUTH);
  setTimeout(() => { window.scrollTo(0, 0); }, 100);
  next();

});

export default router;