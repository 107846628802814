export const i18n_fr = {
    "Board StillOnline": "Board StillOnline",
    "Retour": "Retour",
    "CCO ID" : "CCO ID",
    "SMART ACCOUNT" : "SMART ACCOUNT",
    "Non renseigné" : "Non renseigné",
    "Addresse principale" : "Addresse principale",
    "Votre interlocuteur Stillnetwork" : "Votre interlocuteur Stillnetwork",
    "Votre interlocutrice Stillnetwork" : "Votre interlocutrice Stillnetwork",
    "M." : "M.",
    "Mme." : "Mme.",
    "est joignable :" : "est joignable :",
    "Par mail" : "Par mail",
    "Par téléphone" : "Par téléphone",
    "Oups...": "Oups...",
    "Retourner à l'accueil": "Retourner à l'accueil",
    "Mot de passe oublié ?": "Mot de passe oublié ?",
    "Veuillez nous contacter au 01 60 92 41 00": "Veuillez nous contacter au 01 60 92 41 00",
    "afin de procéder à la vérification de votre identité": "afin de procéder à la vérification de votre identité",
    "et la réinitilisation de votre mot de passe.": "et la réinitilisation de votre mot de passe.",
    "Retour à la page de connexion": "Retour à la page de connexion",
    "Identifiant": "Identifiant",
    "Mot de passe": "Mot de passe",
    "Se connecter": "Se connecter",
    "Chargement...": "Chargement...",
    "Dernières mises à jours" : "Dernières mises à jours",
    "Une nouvelle facture est disponible" : "Une nouvelle facture est disponible",
    "Transmission d'une nouvelle offre commerciale" : "Transmission d'une nouvelle offre commerciale",
    "concernant votre projet" : "concernant votre projet",
    "Partage du fichier" : "Partage du fichier",
    "Notre offre" : "Notre offre",
    "Nos partenaires" : "Nos partenaires",
    "Nous contacter" : "Nous contacter",
    "Nouveau !": "Nouveau !",
    "Chiffres clés": "Chiffres clés",
    "Basés sur vos commandes": "Basés sur vos commandes",
    "Aucune commande": "Aucune commande",
    "Il n'existe aucune commande à afficher correspondant à vos critères de recherche.": "Il n'existe aucune commande à afficher correspondant à vos critères de recherche.",
    "N° Commande Client": "N° Commande Client",
    "N° Offre Stillnetwork": "N° Offre Stillnetwork",
    "Contact Stillnetwork": "Contact Stillnetwork",
    "Date non communiquée": "Date non communiquée",
    "Mise à jour": "Mise à jour",
    "Equipements": "Equipements",
    "Prestation": "Prestation",
    "Support / Abonnement": "Support / Abonnement",
    "Détail de la commande": "Détail de la commande",
    "Vue d'ensemble": "Vue d'ensemble",
    "Commandes en cours": "Commandes en cours",
    "Vue équipements": "Vue équipements",
    "Vue contrats": "Vue contrats",
    "FAQ support": "FAQ support",
    "Gestion des tickets": "Gestion des tickets",
    "Gestion des stocks": "Gestion des stocks",
    "Gestion des sites": "Gestion des sites",
    "Offres commerciales": "Offres commerciales",
    "Commandes": "Commandes",
    "Factures": "Factures",
    "Prochaines échéances": "Prochaines échéances",
    "Aucune échéance": "Aucune échéance",
    "Il n'existe pour le moment aucune échéance à suivre.": "Il n'existe pour le moment aucune échéance à suivre.",
    "Aucune facture": "Aucune facture",
    "Il n'existe aucune facture à afficher correspondant à vos critères de recherche.": "Il n'existe aucune facture à afficher correspondant à vos critères de recherche.",
    "Facture PDF": "Facture PDF",
    "N° Contrat": "N° Contrat",
    "Smart Account": "Smart Account",
    "Date de début": "Date de début",
    "Date de fin": "Date de fin",
    "Facture": "Facture",
    "Date": "Date",
    "Echéance": "Echéance",
    "Contact": "Contact",
    "Devise": "Devise",
    "Total HT": "Total HT",
    "Total TTC": "Total TTC",
    "Total Réglement": "Total Réglement",
    "Total Avoir": "Total Avoir",
    "Solde": "Solde",
    "Facture soldée": "Facture soldée",
    "Offre": "Offre",
    "Livraison": "Livraison",
    "Délai de livraison": "Délai de livraison",
    "Aucune offre": "Aucune offre",
    "Il n'existe aucune offre à afficher correspondant à votre critère de recherche.": "Il n'existe aucune offre à afficher correspondant à votre critère de recherche.",
    "Offre PDF": "Offre PDF",
    "Fichier :": "Fichier :",
    "Envoyer": "Envoyer",
    "Concernant le projet :": "Concernant le projet :",
    "Livraison estimée": "Livraison estimée",
    "Offre Stillnetwork": "Offre Stillnetwork",
    "Montants HT / TTC": "Montants HT / TTC",
    "Contenu de la commande": "Contenu de la commande",
    "Support et Abonnement": "Support et Abonnement",
    "Documents": "Documents",
    "Document": "Document",
    "Aucune ligne à afficher": "Aucune ligne à afficher",
    "Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.": "Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.",
    "En Stock Stillnetwork": "En Stock Stillnetwork",
    "Numéros de série": "Numéros de série",
    "Historique dates": "Historique dates",
    "Concerne l'équipement :": "Concerne l'équipement :",
    "Historique dates de livraison": "Historique dates de livraison",
    "Abonnement": "Abonnement",
    "Support": "Support",
    "En attente": "En attente",
    "Traité": "Traité",
    "En cours de traitement": "En cours de traitement",
    "Equipements liés": "Equipements liés",
    "IMPORTANT": "IMPORTANT",
    "L'ouverture d'un ticket via l'interface Web ou par e-mail le définit automatiquement par défaut sur un ticket de priorité": "L'ouverture d'un ticket via l'interface Web ou par e-mail le définit automatiquement par défaut sur un ticket de priorité",
    "ou": "ou",
    "Si vous avez un cas de priorité": "Si vous avez un cas de priorité",
    "vous devez ouvrir le ticket avec le GCC par téléphone.": "vous devez ouvrir le ticket avec le GCC par téléphone.",
    "Via l'interface CISCO": "Via l'interface CISCO",
    "Afin de procéder à l'ouverture d'un ticket sur votre contrat de support, vous pouvez accèder à l'interface CISCO dédié en cliquant sur le lien suivant :": "Afin de procéder à l'ouverture d'un ticket sur votre contrat de support, vous pouvez accèder à l'interface CISCO dédié en cliquant sur le lien suivant :",
    "Via l'envoi d'un Email": "Via l'envoi d'un Email",
    "Vous pouvez également ouvrir un ticket sur votre contrat de support en envoyant un mail à :": "Vous pouvez également ouvrir un ticket sur votre contrat de support en envoyant un mail à :",
    "Via un appel téléphonique": "Via un appel téléphonique",
    "Il existe plusieurs numéros de téléphone différents à utiliser pour contacter le TAC, en fonction de votre emplacement dans le monde.<br>Les numéros actuels sont listés ci-dessous :": "Il existe plusieurs numéros de téléphone différents à utiliser pour contacter le TAC, en fonction de votre emplacement dans le monde.<br>Les numéros actuels sont listés ci-dessous :",
    "Asia-Pacific  :": "Asia-Pacific  :",
    "North America  :": "North America  :",
    "EMEA   :": "EMEA   :",
    "CISCO - Support manager": "CISCO - Support manager",
    "Vous pouvez également ouvrir un ticket sur votre contrat de support en envoyant un mail sur l'adresse": "Vous pouvez également ouvrir un ticket sur votre contrat de support en envoyant un mail sur l'adresse",
    "Il existe plusieurs numéros de téléphone différents à utiliser pour contacter le TAC, en fonction de votre emplacement dans le monde.": "Il existe plusieurs numéros de téléphone différents à utiliser pour contacter le TAC, en fonction de votre emplacement dans le monde.",
    "Les numéros actuels sont listés ci-dessous :": "Les numéros actuels sont listés ci-dessous :",
    "Extreme Networks": "Extreme Networks",
    "Via l’interface Extreme Networks": "Via l’interface Extreme Networks",
    "Pour ouvrir un ticket de support, il convient de se connecter sur le portail en cliquant sur le lien suivant :": "Pour ouvrir un ticket de support, il convient de se connecter sur le portail en cliquant sur le lien suivant :",
    "(renseigner son e-mail et son mot de passe)": "(renseigner son e-mail et son mot de passe)",
    "Vous pouvez contacter le centre d’assistance d’Extreme Network pour obtenir de l’aide en sélectionnant votre continent puis le pays d’origine sur la page suivante :": "Vous pouvez contacter le centre d’assistance d’Extreme Network pour obtenir de l’aide en sélectionnant votre continent puis le pays d’origine sur la page suivante :",
    "Vous trouverez alors le numéro à contacter depuis votre pays.": "Vous trouverez alors le numéro à contacter depuis votre pays.",
    "Depuis la France :": "Depuis la France :",
    "Si vous rencontrez des problèmes avec le numéro sans frais, essayez-le +1-408-579-2800 comme numéro de téléphone alternatif pour joindre GTAC/RMA.": "Si vous rencontrez des problèmes avec le numéro sans frais, essayez-le +1-408-579-2800 comme numéro de téléphone alternatif pour joindre GTAC/RMA.",
    "Via l'interface FortiCare": "Via l'interface FortiCare",
    "FortiCare - Support manager": "FortiCare - Support manager",
    "Afin de procéder à l'ouverture d'un ticket sur votre contrat de support, vous pouvez accèder à l'interface Fortinet dédié en cliquant sur le lien suivant :": "Afin de procéder à l'ouverture d'un ticket sur votre contrat de support, vous pouvez accèder à l'interface Fortinet dédié en cliquant sur le lien suivant :",
    "Via un chat instantané": "Via un chat instantané",
    "Vous pouvez également discuter en direct avec un technicien Fortinet, accédez au chat en": "Vous pouvez également discuter en direct avec un technicien Fortinet, accédez au chat en",
    "cliquant sur ce lien": "cliquant sur ce lien",
    "Il existe 2 numéros de téléphone différents pour contacter directement le support Fortinet  :": "Il existe 2 numéros de téléphone différents pour contacter directement le support Fortinet  :",
    "Numéro Gratuit  :": "Numéro Gratuit  :",
    "Numéro payant  :": "Numéro payant  :",
    "JUNIPER - Support manager": "JUNIPER - Support manager",
    "Via l'interface JUNIPER": "Via l'interface JUNIPER",
    "Pour trouver des informations de support détaillées sur votre produit, rendez-vous sur la page :": "Pour trouver des informations de support détaillées sur votre produit, rendez-vous sur la page :",
    "ou connectez-vous avec votre compte Juniper sur la page :": "ou connectez-vous avec votre compte Juniper sur la page :",
    "Via le chat Juniper": "Via le chat Juniper",
    "Pour l’assistance mondiale, utilisez l'icône Chat en bas à droite de la page :": "Pour l’assistance mondiale, utilisez l'icône Chat en bas à droite de la page :",
    "pour demander une assistance 24 heures sur 24, 7 jours sur 7.": "pour demander une assistance 24 heures sur 24, 7 jours sur 7.",
    "Depuis la France, composez le": "Depuis la France, composez le",
    "Depuis l’étranger, utilisez un numéro de pays répertorié dans l’un des onglets de la page :": "Depuis l’étranger, utilisez un numéro de pays répertorié dans l’un des onglets de la page :",
    "Polycom": "Polycom",
    "Vous pouvez contacter l’assistante par téléphone :": "Vous pouvez contacter l’assistante par téléphone :",
    "Pour le support contractuel": "Pour le support contractuel",
    "Assistance en anglais disponible 24h / 24 et 7j / 7": "Assistance en anglais disponible 24h / 24 et 7j / 7",
    "Pour le support standard": "Pour le support standard",
    "Du lundi au vendredi - de 9h00  à 17h30": "Du lundi au vendredi - de 9h00  à 17h30",
    "Depuis la france :": "Depuis la france :",
    "Pour contacter le support partout dans le monde, vous pouvez utiliser les numéros que vous trouverez dans la liste via le lien :": "Pour contacter le support partout dans le monde, vous pouvez utiliser les numéros que vous trouverez dans la liste via le lien :",
    "Via un formulaire": "Via un formulaire",
    "Vous devez compléter le formulaire d’assistante sur la page suivante :": "Vous devez compléter le formulaire d’assistante sur la page suivante :",
    "SYNOLOGY": "SYNOLOGY",
    "Via l’interface Synology": "Via l’interface Synology",
    "Pour soumettre un ticket d’assistance ou suivre son statut, connectez-vous à votre compte Synology en cliquant sur le lien suivant :": "Pour soumettre un ticket d’assistance ou suivre son statut, connectez-vous à votre compte Synology en cliquant sur le lien suivant :",
    "Le service client Synology de France est joignable du lundi au vendredi de 9h à 17h :": "Le service client Synology de France est joignable du lundi au vendredi de 9h à 17h :",
    "Constructeurs": "Constructeurs",
    "Parc client sous support": "Parc client sous support",
    "Par gamme de produits": "Par gamme de produits",
    "Aucun équipement": "Aucun équipement",
    "Il n'existe pour le moment aucun équipement sous support dans notre base.": "Il n'existe pour le moment aucun équipement sous support dans notre base.",
    "Equipement": "Equipement",
    "Vue par équipement": "Vue par équipement",
    "Vue par contrat": "Vue par contrat",
    "CONTRAT ACTIF": "CONTRAT ACTIF",
    "CONTRAT INACTIF": "CONTRAT INACTIF",
    "CONTRAT ARCHIVE": "CONTRAT ARCHIVE",
    "Contrat sans libellé": "Contrat sans libellé",
    "Echéance au": "Echéance au",
    "Echéance inconnue": "Echéance inconnue",
    "Archiver le contrat": "Archiver le contrat",
    "Désarchiver le contrat": "Désarchiver le contrat",
    "Demande de renouvellement": "Demande de renouvellement",
    "Cette demande sera envoyée directement à votre interlocteur Stillnetwork, qui reprendra contact avec vous rapidement": "Cette demande sera envoyée directement à votre interlocteur Stillnetwork, qui reprendra contact avec vous rapidement",
    "afin de faire le point sur votre besoin.": "afin de faire le point sur votre besoin.",
    "Annuler": "Annuler",
    "Envoyer votre demande": "Envoyer votre demande",
    "Ouvrir un ticket de support": "Ouvrir un ticket de support",
    "Aucun Contrat": "Aucun Contrat",
    "Il n'existe aucune ligne dans le contrat à afficher correspondant à vos critères de recherche.": "Il n'existe aucune ligne dans le contrat à afficher correspondant à vos critères de recherche.",
    "SN :": "SN :",
    "Du": "Du",
    "au": "au",
    "Garantie jusqu'au": "Garantie jusqu'au",
    "Hors garantie depuis le": "Hors garantie depuis le",
    "Matériel supporté jusqu'au": "Matériel supporté jusqu'au",
    "Offre commerciale N°": "Offre commerciale N°",
    "du": "du",
    "Discussion autour du ticket": "Discussion autour du ticket",
    "T'Chat global Still ERP !": "T'Chat global Still ERP !",
    "Votre commande": "Votre commande",
    "Aucune offre à afficher.": "Aucune offre à afficher.",
    "Date de livraison": "Date de livraison",
    "Livraison le": "Livraison le",
    "mise à jour le": "mise à jour le",
    "Référence / Désignation": "Référence / Désignation",
    "Qté": "Qté",
    "Statut": "Statut",
    "Actions": "Actions",
    "La date de livraison n'a connue aucune variation depuis le": "La date de livraison n'a connue aucune variation depuis le",
    "la date de livraison est passée du": "la date de livraison est passée du",
    "Projet": "Projet",
    "Demandeur": "Demandeur",
    "Montants HT": "Montants HT",
    "Afficher": "Afficher",
    "Demandeurs": "Demandeurs",
    "Status": "Status",
    "Rechercher dans la liste": "Rechercher dans la liste",
    "Filter par": "Filter par",
    "Oui": "Oui",
    "Non": "Non",
    "Exporter et télécharger la liste au format Excel ?": "Exporter et télécharger la liste au format Excel ?",
    "Exporter": "Exporter",
    "Facture N°": "Facture N°",
    "DU": "DU",
    "AU": "AU",
    "Voulez-vous vraiment archiver ce contrat ?": "Voulez-vous vraiment archiver ce contrat ?",
    "OUI": "OUI",
    "Non, ne pas archiver": "Non, ne pas archiver",
    "Voulez-vous vraiment désarchiver ce contrat ?": "Voulez-vous vraiment désarchiver ce contrat ?",
    "Non, ne pas désarchiver": "Non, ne pas désarchiver",
    "Facultatif : Veuillez saisir ici un complément d'information concernant votre demande de renouvellement.": "Facultatif : Veuillez saisir ici un complément d'information concernant votre demande de renouvellement.",
    "Ouvrir un case CISCO": "Ouvrir un case CISCO",
    "Déployer toute les références majeures": "Déployer toute les références majeures",
    "Aucune ligne de contrat à afficher.": "Aucune ligne de contrat à afficher.",
    "Produit": "Produit",
    "Période support": "Période support",
    "Serv. Level": "Serv. Level",
    "Garantie": "Garantie",
    "Site": "Site",
    "Type de support": "Type de support",
    "Succés": "Succés",
    "Demande de renouvellement envoyée à votre interlocuteur Stillnetwork.": "Demande de renouvellement envoyée à votre interlocuteur Stillnetwork.",
    "Archivage": "Archivage",
    "Ce contrat est maintenant considéré comme": "Ce contrat est maintenant considéré comme",
    "archivé": "archivé",
    "désarchivé": "désarchivé",
    "Abonnements": "Abonnements",
    "Licence": "Licence",
    "Contrats de support": "Contrats de support",
    "Support matériel / Logiciel": "Support matériel / Logiciel",
    "Parc client": "Parc client",
    "Nombre d'équipements": "Nombre d'équipements",
    "Outil de déploiement": "Outil de déploiement",
    "Nombre de tickets": "Nombre de tickets",
    "Contrat": "Contrat",
    "matériel": "matériel",
    "Ticket": "Ticket",
    "Aucune": "Aucune",
    "Aucun": "Aucun",
    "Aucun ticket": "Aucun ticket",
    "Date commande": "Date commande",
    "Contenu": "Contenu",
    "Aucune commande à afficher.": "Aucune commande à afficher.",
    "Numéro de facture": "Numéro de facture",
    "Aucune facture à afficher.": "Aucune facture à afficher.",
    "Ecrire un nouveau message...": "Ecrire un nouveau message...",
    "en cours": "en cours",
    "expire dans moins de 3 mois": "expire dans moins de 3 mois",
    "expiré(s)": "expiré(s)",
    "N°": "N°",
    "Détail de votre contrat": "Détail de votre contrat",
    "Demande de renouvellement concernant de l'équipement": "Demande de renouvellement concernant de l'équipement",
    "Equipements prochainement non supportés": "Equipements prochainement non supportés",
    "Fin de support de": "Fin de support de",
    "Contrat N°": "Contrat N°",
    "Détail de l'équipement": "Détail de l'équipement",
    "Vos données de contrats à importer": "Vos données de contrats à importer",
    "Attention,": "Attention,",
    "incohérences détéctées, veuillez les corriger avant de continuer, svp.": "incohérences détéctées, veuillez les corriger avant de continuer, svp.",
    "Votre fichier semble valide, vous pouvez dés à présent lancer l'import.": "Votre fichier semble valide, vous pouvez dés à présent lancer l'import.",
    "Importer la liste de contrat": "Importer la liste de contrat",
    "Matrice Contrats Stillnetwork": "Matrice Contrats Stillnetwork",
    "Tout d'abord, afin de pouvoir importer vos contrats, veuillez": "Tout d'abord, afin de pouvoir importer vos contrats, veuillez",
    "cliquer ici pour télécharger notre matrice": "cliquer ici pour télécharger notre matrice",
    'Le fichier Excel téléchargé comporte deux onglets. Le premier "Informations" vous guidera sur la saisie des champs, le second "Contrats" sera votre référentiel de contrats que nous importerons.': 'Le fichier Excel téléchargé comporte deux onglets. Le premier "Informations" vous guidera sur la saisie des champs, le second "Contrats" sera votre référentiel de contrats que nous importerons.',
    "Upload de votre fichier": "Upload de votre fichier",
    "Veuillez cliquer pour afficher l'explorateur et importer votre fichier.": "Veuillez cliquer pour afficher l'explorateur et importer votre fichier.",
    "Vérification de la cohérence de votre fichier": "Vérification de la cohérence de votre fichier",
    "Attention, seule la matrice Excel fournie ci-dessus peut être importée, veuillez noter que le fichier ne peut être supérieur à 2 MO.": "Attention, seule la matrice Excel fournie ci-dessus peut être importée, veuillez noter que le fichier ne peut être supérieur à 2 MO.",
    "ID:": "ID:",
    "Parent:": "Parent:",
    "Import de vos données en cours": "Import de vos données en cours",
    "Upload": "Upload",
    "Envoyer la matrice": "Envoyer la matrice",
    "Vérification": "Vérification",
    "Test de cohérence du fichier": "Test de cohérence du fichier",
    "Traitement": "Traitement",
    "Import des données": "Import des données",
    "N° de contrat vide": "N° de contrat vide",
    "Aucun constructeur": "Aucun constructeur",
    "Référence vide": "Référence vide",
    "Aucune description": "Aucune description",
    "N° de série vide": "N° de série vide",
    "Date de début vide": "Date de début vide",
    "Date de fin vide": "Date de fin vide",
    "Succès": "Succès",
    "Import de vos contrats réalisé avec succès !": "Import de vos contrats réalisé avec succès !",
    "Importer des contrats / Equipements": "Importer des contrats / Equipements",
    "Il n'existe aucun contrat à afficher correspondant à vos critères de recherche.": "Il n'existe aucun contrat à afficher correspondant à vos critères de recherche.",
    "Contrat archivé": "Contrat archivé",
    "Contrat importé": "Contrat importé",
    "SmartAccount:": "SmartAccount:",
    "commande": "commande",
    "s": "s",
    "Aucune commande liée": "Aucune commande liée",
    "Détail du contrat": "Détail du contrat",
    "Contrat précédent": "Contrat précédent",
    "Contrat suivant": "Contrat suivant",
    "Annuler l'import": "Annuler l'import",
    "Importer des contrat de support": "Importer des contrat de support",
    "Type": "Type",
    "Libellé du contrat": "Libellé du contrat",
    "Période": "Période",
    "SmartAccount": "SmartAccount",
    "Constructeur": "Constructeur",
    "L'identifiant doit être un email valide": "L'identifiant doit être un email valide",
    "L'identifiant est obligatoire": "L'identifiant est obligatoire",
    "Le mot de passe est obligatoire": "Le mot de passe est obligatoire",
    "Connexion établie !": "Connexion établie !",
    "Bienvenue": "Bienvenue",
    "sur votre board StillOnline !": "sur votre board StillOnline !",
    "Accèder à StillOnline": "Accèder à StillOnline",
    "Il semble y avoir une erreur, veuillez vérifier vous identifiants de connexion.": "Il semble y avoir une erreur, veuillez vérifier vous identifiants de connexion.",
    "Retourner sur la page de connexion": "Retourner sur la page de connexion",
    "expiration(s) avant M-3": "expiration(s) avant M-3",
    "contrat": "contrat",
    "Aucune contrat lié": "Aucune contrat lié",
    "Détail du produit": "Détail du produit",
    "Aucun contrat à afficher.": "Aucun contrat à afficher.",
    "Gamme": "Gamme",
    "Quantité": "Quantité",
    "Contrats": "Contrats",
    "Type de produit": "Type de produit",
    "Vos contrats à échéance": "Vos contrats à échéance",
    "contrat(s) expirés ou bientôt expirés": "contrat(s) expirés ou bientôt expirés",
    "Il n'existe pour le moment aucune échéance de contrat à suivre.": "Il n'existe pour le moment aucune échéance de contrat à suivre.",
    "Contrat actif jusqu'au": "Contrat actif jusqu'au",
    "Contrat inactif depuis le": "Contrat inactif depuis le",
    "Dernières mises à jours sur vos tickets": "Dernières mises à jours sur vos tickets",
    "Vous n'avez créer aucun ticket pour le moment.": "Vous n'avez créer aucun ticket pour le moment.",
    "Dates": "Dates",
    "CCO / Smart Account": "CCO / Smart Account",
    "TICKET": "TICKET",
    "Validation du ticket": "Validation du ticket",
    "Votre interlocuteur Stillnetwork vérifiera la bonne cohérence de cette demande, aucune action ne sera déclenchée avant sa validation.": "Votre interlocuteur Stillnetwork vérifiera la bonne cohérence de cette demande, aucune action ne sera déclenchée avant sa validation.",
    "Nos équipes ont reçu une notification et traiteront votre demande dans les plus brefs délais.": "Nos équipes ont reçu une notification et traiteront votre demande dans les plus brefs délais.",
    "Commentaires Stillnetwork :": "Commentaires Stillnetwork :",
    "Fichier(s) concernant la validation :": "Fichier(s) concernant la validation :",
    "Staging": "Staging",
    "Stager le  matériel avec les configurations suivantes :": "Stager le  matériel avec les configurations suivantes :",
    "Afficher la liste complète...": "Afficher la liste complète...",
    "Masquer une partie de la liste...": "Masquer une partie de la liste...",
    "Expédition": "Expédition",
    "Sortie du stock du matériel suivant :": "Sortie du stock du matériel suivant :",
    "Quantité :": "Quantité :",
    "Prévision de stock :": "Prévision de stock :",
    "Expédition du matériel dés que possible à l'adresse :": "Expédition du matériel dés que possible à l'adresse :",
    "Expédition du matériel à partir du": "Expédition du matériel à partir du",
    "à l'adresse :": "à l'adresse :",
    "Blocage du matériel en vue d'une livraison future.": "Blocage du matériel en vue d'une livraison future.",
    "Notes spécifiques à destination de l'équipe Stilnetwork gérant le stock :": "Notes spécifiques à destination de l'équipe Stilnetwork gérant le stock :",
    "Fichier(s) concernant l'expédition :": "Fichier(s) concernant l'expédition :",
    "Déploiement": "Déploiement",
    "Description de votre besoin de déploiement :": "Description de votre besoin de déploiement :",
    "Fichier(s) concernant le déploiement :": "Fichier(s) concernant le déploiement :",
    "Lieu du déploiement :": "Lieu du déploiement :",
    "Contact :": "Contact :",
    "Description de votre besoin :": "Description de votre besoin :",
    "Prestation de service :": "Prestation de service :",
    "Première date d'intervention souhaitée le": "Première date d'intervention souhaitée le",
    "Seconde date d'intervention souhaitée le": "Seconde date d'intervention souhaitée le",
    "Troisième date d'intervention souhaitée le": "Troisième date d'intervention souhaitée le",
    "Intervention du technicien en date du": "Intervention du technicien en date du",
    "Intervention du technicien en cours de planificiation.": "Intervention du technicien en cours de planificiation.",
    "Technicien effectuant la prestation :": "Technicien effectuant la prestation :",
    "Fichier(s) joint :": "Fichier(s) joint :",
    "Supprimer": "Supprimer",
    "Adresse :": "Adresse :",
    "Modifier les commentaires Stillnetwork :": "Modifier les commentaires Stillnetwork :",
    "Appliquer": "Appliquer",
    "Facturation": "Facturation",
    "Adresse de facturation :": "Adresse de facturation :",
    "Commentaires modifié": "Commentaires modifié",
    "Saisir vos commentaires": "Saisir vos commentaires",
    "Ce ticket est en attente de validation": "Ce ticket est en attente de validation",
    "Ce ticket nécessitera dans un premier temps une validation de Stillnetwork": "Ce ticket nécessitera dans un premier temps une validation de Stillnetwork",
    "Ce ticket a été validé": "Ce ticket a été validé",
    "Ce ticket a été expédié": "Ce ticket a été expédié",
    "Ce ticket a été déployé": "Ce ticket a été déployé",
    "Ce ticket a été facturé": "Ce ticket a été facturé",
    "Oui, le supprimer !": "Oui, le supprimer !",
    "Opération irréversible, voulez-vous supprimer ce fichier ?": "Opération irréversible, voulez-vous supprimer ce fichier ?",
    "Validation": "Validation",
    "Retour à la gestion des sites": "Retour à la gestion des sites",
    "Informations": "Informations",
    "Généralités sur le site": "Généralités sur le site",
    "Adresse détaillée": "Adresse détaillée",
    "Où se trouve le site ?": "Où se trouve le site ?",
    "Contacts": "Contacts",
    "Sélection des contacts du site": "Sélection des contacts du site",
    "Et quelques informations facultatives...": "Et quelques informations facultatives...",
    "Informations principales du site": "Informations principales du site",
    "Libellé du site": "Libellé du site",
    "Afficher ce site sur la carte ?": "Afficher ce site sur la carte ?",
    "Si décoché, le site sera tout de même affiché dans le listing des sites.": "Si décoché, le site sera tout de même affiché dans le listing des sites.",
    "Sélectionnez le type de site": "Sélectionnez le type de site",
    "Site de livraison": "Site de livraison",
    "Livraison d'équipement": "Livraison d'équipement",
    "Siège sociale / Centre d'affaires": "Siège sociale / Centre d'affaires",
    "Adresse administrative": "Adresse administrative",
    "Site de stockage": "Site de stockage",
    "Centre logistique / Emplacement stratégique": "Centre logistique / Emplacement stratégique",
    "Localisation exacte de votre site.": "Localisation exacte de votre site.",
    "Adresse (Numéro et rue)": "Adresse (Numéro et rue)",
    "Complément": "Complément",
    "Précision sur le batiment, la porte spécifique, l'accès...": "Précision sur le batiment, la porte spécifique, l'accès...",
    "Code postal / Ville / Pays": "Code postal / Ville / Pays",
    "Géolocalisation": "Géolocalisation",
    "Rechercher les coordonnées de l'adresse du site": "Rechercher les coordonnées de l'adresse du site",
    "Veuillez sélectionnez les personnes liées au site.": "Veuillez sélectionnez les personnes liées au site.",
    "Créer un nouveau contact": "Créer un nouveau contact",
    "Coordonnées": "Coordonnées",
    "Monsieur": "Monsieur",
    "Madame": "Madame",
    "Créer le contact": "Créer le contact",
    "Dernière étape !": "Dernière étape !",
    "Si vous le souhaitez, vous pouvez aouter des notes complémentaires concernant ce site.": "Si vous le souhaitez, vous pouvez aouter des notes complémentaires concernant ce site.",
    "Veuillez patienter...": "Veuillez patienter...",
    "Continuer": "Continuer",
    "Le libellé du site est obligatoire pour continuer.": "Le libellé du site est obligatoire pour continuer.",
    "Le libellé du type est obligatoire pour continuer.": "Le libellé du type est obligatoire pour continuer.",
    "Opération réalisée avec succés !": "Opération réalisée avec succés !",
    "Ajouter un nouveau site": "Ajouter un nouveau site",
    "Création du site": "Création du site",
    "Ajout d'un nouveau site": "Ajout d'un nouveau site",
    "Modification d'un site": "Modification d'un site",
    "Modifier le site": "Modifier le site",
    "Comment souhaitez-vous nommer ce site ?": "Comment souhaitez-vous nommer ce site ?",
    "Type spécifique": "Type spécifique",
    "Prénom": "Prénom",
    "Retour à la liste de vos tickets": "Retour à la liste de vos tickets",
    "Création du ticket": "Création du ticket",
    "Informations principales sur le ticket": "Informations principales sur le ticket",
    "Libellé du ticket": "Libellé du ticket",
    "Veuillez sélectionnez un ou plusieurs types de services": "Veuillez sélectionnez un ou plusieurs types de services",
    "Liste du matériel": "Liste du matériel",
    "Veuillez spécifier les quantités à expédier sur vos produits.": "Veuillez spécifier les quantités à expédier sur vos produits.",
    "Aucun équipement de stock": "Aucun équipement de stock",
    "Il n'existe aucun équipement de stock à afficher correspondant à vos critères de recherche.": "Il n'existe aucun équipement de stock à afficher correspondant à vos critères de recherche.",
    "Il existe": "Il existe",
    "référence(s) masquée(s), cliquer ici pour les afficher": "référence(s) masquée(s), cliquer ici pour les afficher",
    "échéance au": "échéance au",
    "actuellement disponible.": "actuellement disponible.",
    "Aucun stock": "Aucun stock",
    "en réappro. dés le": "en réappro. dés le",
    "date en attente": "date en attente",
    "Quelques informations sont requises afin d'assurer la sortie du stock.": "Quelques informations sont requises afin d'assurer la sortie du stock.",
    "Concernant l'expèdition en elle même, vous souhaitez :": "Concernant l'expèdition en elle même, vous souhaitez :",
    "Envoyer le matériel dés que possible": "Envoyer le matériel dés que possible",
    "Envoyer le matériel à partir d'une date": "Envoyer le matériel à partir d'une date",
    "Bloquer le stock de matériel pour une livraison future": "Bloquer le stock de matériel pour une livraison future",
    "Veuillez choisir la date d'expédition ci-dessous": "Veuillez choisir la date d'expédition ci-dessous",
    "Avez-vous une precision à apporter à notre équipe gérant le stock ?": "Avez-vous une precision à apporter à notre équipe gérant le stock ?",
    "Adresse et Contact": "Adresse et Contact",
    "Veuillez parcourir les onglets ci-dessous afin de spécifier les adresses et contacts.": "Veuillez parcourir les onglets ci-dessous afin de spécifier les adresses et contacts.",
    "L'adresse concernant": "L'adresse concernant",
    "est-elle identique à l'adresse principale ?": "est-elle identique à l'adresse principale ?",
    "Oui, c'est la même adresse": "Oui, c'est la même adresse",
    "Non, l'adresse est différente.": "Non, l'adresse est différente.",
    "Affichage des sites de la société": "Affichage des sites de la société",
    "Veuillez sélectionner une adresse et un contact dans la liste suivante :": "Veuillez sélectionner une adresse et un contact dans la liste suivante :",
    "Aucun libellé": "Aucun libellé",
    "Service Spécifique": "Service Spécifique",
    "Afin de vous offir la meilleure prestation, nous avons besoin d'informations spécifiques.": "Afin de vous offir la meilleure prestation, nous avons besoin d'informations spécifiques.",
    "Pourriez-vous décrire les élements souhaités": "Pourriez-vous décrire les élements souhaités",
    "pour la prestation d'ingénierie ?": "pour la prestation d'ingénierie ?",
    "Quand souhaiteriez-vous que nous programmions l'intervention ?": "Quand souhaiteriez-vous que nous programmions l'intervention ?",
    "Première date privilégiée :": "Première date privilégiée :",
    "Deuxième date :": "Deuxième date :",
    "Troisième date :": "Troisième date :",
    "Existe-t-il des fichiers à nous transmettre en lien avec cette prestation ?": "Existe-t-il des fichiers à nous transmettre en lien avec cette prestation ?",
    "Ajouter un fichier": "Ajouter un fichier",
    "Transmettez-nous vos fichiers des configurations et les informations spécifiques sur vos matériels": "Transmettez-nous vos fichiers des configurations et les informations spécifiques sur vos matériels",
    "Synthèse et étapes du ticket": "Synthèse et étapes du ticket",
    "Création d'un nouveau ticket": "Création d'un nouveau ticket",
    "Quantité Disponible": "Quantité Disponible",
    "Saisir quantité": "Saisir quantité",
    "Fichiers": "Fichiers",
    "Commentaires": "Commentaires",
    "Libellé": "Libellé",
    "Adresse": "Adresse",
    "informations": "informations",
    "Généralités sur le ticket": "Généralités sur le ticket",
    "Quel matériel est concerné ?": "Quel matériel est concerné ?",
    "Configuration du matériel": "Configuration du matériel",
    "Organisez la sortie de matériels": "Organisez la sortie de matériels",
    "Prestations d'ingénierie": "Prestations d'ingénierie",
    "Adresse et contact": "Adresse et contact",
    "Sélection de site(s)": "Sélection de site(s)",
    "Synthèse du ticket": "Synthèse du ticket",
    "Et création de votre ticket.": "Et création de votre ticket.",
    "Retour à la liste des tickets": "Retour à la liste des tickets",
    "Attention, le champ": "Attention, le champ",
    "ne peut être vide.": "ne peut être vide.",
    "Attention, vous devez saisir une date.": "Attention, vous devez saisir une date.",
    "Attention, vous devez sélectionner une adresse principale.": "Attention, vous devez sélectionner une adresse principale.",
    "Attention, aucune reférence commande n'a été sélectionnée pour la référence": "Attention, aucune reférence commande n'a été sélectionnée pour la référence",
    "Attention, aucune quantité n'a été saisie.": "Attention, aucune quantité n'a été saisie.",
    "Attention, la taille du fichier ne peut dépasser 10 MO !": "Attention, la taille du fichier ne peut dépasser 10 MO !",
    "Ticket créé avec succés !": "Ticket créé avec succés !",
    "Facultatif, si vous le souhaitez, vous pouvez nommer ce ticket.": "Facultatif, si vous le souhaitez, vous pouvez nommer ce ticket.",
    "choix de la date": "choix de la date",
    "Type d'intervention, nombre de jours, compétences techniques...": "Type d'intervention, nombre de jours, compétences techniques...",
    "Premier choix de date": "Premier choix de date",
    "Second choix de date": "Second choix de date",
    "Dernier choix de date": "Dernier choix de date",
    "Commentaires spécifique...": "Commentaires spécifique...",
    "Aucune ligne à afficher.": "Aucune ligne à afficher.",
    "Commande Stillnetwork :": "Commande Stillnetwork :",
    "Adresse principale": "Adresse principale",
    "de": "de",
    "Aucun site à afficher.": "Aucun site à afficher.",
    "Image(s) jointe(s) :": "Image(s) jointe(s) :",
    "Oui, la supprimer !": "Oui, la supprimer !",
    "Opération irréversible, voulez-vous supprimer cette image ?": "Opération irréversible, voulez-vous supprimer cette image ?",
    "En quelques chiffres": "En quelques chiffres",
    "Créer un nouveau ticket": "Créer un nouveau ticket",
    "ticket": "ticket",
    "en cours de traitement": "en cours de traitement",
    "traité": "traité",
    "Référence": "Référence",
    "en stock": "en stock",
    "référence": "référence",
    "disponible": "disponible",
    "en réappro.": "en réappro.",
    "épuisée": "épuisée",
    "Sites": "Sites",
    "sites": "sites",
    "Libellé :": "Libellé :",
    "Afficher les sites": "Afficher les sites",
    "Sur une carte": "Sur une carte",
    "En listing": "En listing",
    "Aucun contact": "Aucun contact",
    "Créer un nouveau site": "Créer un nouveau site",
    "Adresse (Numéro et rue": "Adresse (Numéro et rue)",
    "Corporation": "Corporation",
    "Sole Proprietorship": "Sole Proprietorship",
    "Non-profit": "Non-profit",
    "Limited Liability": "Limited Liability",
    "General Partnership": "General Partnership",
    "Créer le site": "Créer le site",
    "Vue rapide": "Vue rapide",
    "Mises à jours": "Mises à jours",
    "Modifier les détails du site": "Modifier les détails du site",
    "Aucune livraison n'est prévue sur ce site": "Aucune livraison n'est prévue sur ce site",
    "autre": "autre",
    "projet": "projet",
    "Prochaine livraison prévue le": "Prochaine livraison prévue le",
    "Concerne le projet": "Concerne le projet",
    "Livraison projet": "Livraison projet",
    "Indisponible": "Indisponible",
    "dés le": "dés le",
    "Historique": "Historique",
    "Détails du stock": "Détails du stock",
    "Concerne votre équipement": "Concerne votre équipement",
    "Stock en cours :": "Stock en cours :",
    "Quantité disponible :": "Quantité disponible :",
    "Aucune disponibilité pour le moment": "Aucune disponibilité pour le moment",
    "Réapprovisionnement :": "Réapprovisionnement :",
    "Historique du stock": "Historique du stock",
    "Initialisation du stock :": "Initialisation du stock :",
    "par": "par",
    "Sortie du stock :": "Sortie du stock :",
    "N° PO": "N° PO",
    "Qté Dispo / Réappro": "Qté Dispo / Réappro",
    "Echéance stock": "Echéance stock",
    "Réapprovisionnement": "Réapprovisionnement",
    "Disponibilité": "Disponibilité",
    "Synthèse de vos projets": "Synthèse de vos projets",
    "Ticket(s)": "Ticket(s)",
    "Filtrer la liste :": "Filtrer la liste :",
    "Il n'existe aucun ticket à afficher correspondant à vos critères de recherche.": "Il n'existe aucun ticket à afficher correspondant à vos critères de recherche.",
    "Afficher le ticket": "Afficher le ticket",
    "Détail du ticket": "Détail du ticket",
    "Ticket précédent": "Ticket précédent",
    "Ticket suivant": "Ticket suivant",
    "Créer votre premier ticket": "Créer votre premier ticket",
    "Synthèse et filtres": "Synthèse et filtres",
    "Répartition par demandeurs :": "Répartition par demandeurs :",
    "Répartition par services :": "Répartition par services :",
    "Répartition par statuts :": "Répartition par statuts :",
    "Liste des tickets": "Liste des tickets",
    "Planning": "Planning",
    "cartographie": "cartographie",
    "Vous n'avez créer aucun ticket pour le moment": "Vous n'avez créer aucun ticket pour le moment",
    "Aucun ticket à afficher.": "Aucun ticket à afficher.",
    "Création": "Création",
    "Services": "Services",
    "Planification": "Planification",
    "Intervenant :": "Intervenant :",
    "Commande": "Commande",
    "Client": "Client",
    "Partiellement livrée": "Partiellement livrée",
    "Ref Devis": "Ref Devis",
    "N° BC Still'": "N° BC Still'",
    "N° BC Client": "N° BC Client",
    "Votre N° SO": "Votre N° SO",
    "Votre N° PO": "Votre N° PO",
    "N° SO Cisco": "N° SO Cisco",
    "Fraicheur :": "Fraicheur :",
    "PDF": "PDF",
    "Livraison Partielle ?": "Livraison Partielle ?",
    "A définir": "A définir",
    "fichier": "fichier",
    "Aucun fichier": "Aucun fichier",
    "Joindre un nouveau fichier": "Joindre un nouveau fichier",
    "Chargement du fichier...": "Chargement du fichier...",
    "Répartition par intervenant :": "Répartition par intervenant :",
    "Répartition par statut :": "Répartition par statut :",
    "Planifier": "Planifier",
    "Intervenant à définir": "Intervenant à définir",
    "Vous n'avez aucun ticket à traiter pour le moment": "Vous n'avez aucun ticket à traiter pour le moment",
    "Planning d'interventions": "Planning d'interventions",
    "Intervenant": "Intervenant",
    "Pièces jointes": "Pièces jointes",
    "Impossible ! Aucune date d'intervention !": "Impossible ! Aucune date d'intervention !",
    "Erreur": "Erreur",
    "OK": "OK",
    "Impossible ! Aucun intervenant défini !": "Impossible ! Aucun intervenant défini !",
    'Une fois que le statut sera passé à "Réalisé", il vous sera impossible de modifier l\'intervenant et la date du ticket. Souhaitez-vous passer ce ticket en "Réalisé" ?': 'Une fois que le statut sera passé à "Réalisé", il vous sera impossible de modifier l\'intervenant et la date du ticket. Souhaitez-vous passer ce ticket en "Réalisé" ?',
    "Confirmation": "Confirmation",
    "Non, annuler": "Non, annuler",
    "Fichier ajouté": "Fichier ajouté",
    "Détails de votre profil utilisateur": "Détails de votre profil utilisateur",
    "Prénom Nom": "Prénom Nom",
    "Votre fonction": "Votre fonction",
    "Téléphone Fixe": "Téléphone Fixe",
    "Téléphone portable": "Téléphone portable",
    "Votre Cisco CCO ID": "Votre Cisco CCO ID",
    "Enregistrer les changements": "Enregistrer les changements",
    "Veuillez patientez...": "Veuillez patientez...",
    "Profil de connexion": "Profil de connexion",
    "Votre adresse Email": "Votre adresse Email",
    "Veuillez saisir votre adresse Email": "Veuillez saisir votre adresse Email",
    "Confirmer votre mot de passe": "Confirmer votre mot de passe",
    "Modifier votre Email": "Modifier votre Email",
    "Changer votre adresse mail": "Changer votre adresse mail",
    "Le mot de passe doit faire minimum 8 caractères.": "Le mot de passe doit faire minimum 8 caractères.",
    "Mot de passe actuel": "Mot de passe actuel",
    "Nouveau mot de passe": "Nouveau mot de passe",
    "Confirmation de votre nouveau mot de passe": "Confirmation de votre nouveau mot de passe",
    "Modifier votre mot de passe": "Modifier votre mot de passe",
    "Changer votre mot de passe": "Changer votre mot de passe",
    "Nom de famille": "Nom de famille",
    "Fonction": "Fonction",
    "Téléphone": "Téléphone",
    "Cisco CCO ID": "Cisco CCO ID",
    "Email Address": "Email Address",
    "Le prénom est obligatoire": "Le prénom est obligatoire",
    "Le nom est obligatoire": "Le nom est obligatoire",
    "La fonction est obligatoire": "La fonction est obligatoire",
    "Télephone Fixe": "Télephone Fixe",
    "Télephone Portable": "Télephone Portable",
    "Email": "Email",
    "Password": "Password",
    "Mot de passe actuel obligatoire": "Mot de passe actuel obligatoire",
    "Current password": "Current password",
    "Confirmation du nouveau Mot de passe obligatoire": "Confirmation du nouveau Mot de passe obligatoire",
    "Les nouveaux mots des passes doivent être identiques": "Les nouveaux mots des passes doivent être identiques",
    "Password Confirmation": "Password Confirmation",
    "Changement de vos informations personnelles correctement effectué !": "Changement de vos informations personnelles correctement effectué !",
    "Ok": "Ok",
    "Changement de votre Email correctement effectué !": "Changement de votre Email correctement effectué !",
    "Erreur lors du chagement de mail. Veuillez vérifier votre adresse Email et votre mot de passe, svp.": "Erreur lors du chagement de mail. Veuillez vérifier votre adresse Email et votre mot de passe, svp.",
    "Changement de votre mot de passe correctement effectué !": "Changement de votre mot de passe correctement effectué !",
    "Erreur lors du chagement de mot de passe. Veuillez vérifier vos informations et réessayer, svp.": "Erreur lors du chagement de mot de passe. Veuillez vérifier vos informations et réessayer, svp.",
    "Paramètres du compte": "Paramètres du compte",
    "Déconnexion": "Déconnexion",
    "Overview": "Overview",
    "Liste des commandes en cours": "Liste des commandes en cours",
    "Liste des factures": "Liste des factures",
    "Liste des offres commerciales": "Liste des offres commerciales",
    "Détail d'une commande | Prestations": "Détail d'une commande | Prestations",
    "Détail d'une commande": "Détail d'une commande",
    "Détail d'une commande | Support et abonnements": "Détail d'une commande | Support et abonnements",
    "Gestion des Contrats de support & Abonnements": "Gestion des Contrats de support & Abonnements",
    "Vos équipements sous contrat de support": "Vos équipements sous contrat de support",
    "Votre nouveau projet": "Votre nouveau projet",
    "Outils de déploiement": "Outils de déploiement",
    "Suivi des commandes fournisseur": "Suivi des commandes fournisseur",
    "Suivi des prestations": "Suivi des prestations",
    "Gestion des contrats": "Gestion des contrats",
    "Gestion commerciale ": "Gestion commerciale ",
    "Numéro de Série": "Numéro de Série",
    "IDs": "IDs",
    "Source": "Source",
    "Cohérence": "Cohérence",
    "Interface Web Cisco": "Interface Web Cisco",
    "Accès par Email": "Accès par Email",
    "Accès par téléphone": "Accès par téléphone",
    "carte": "carte",
    "liste": "liste",
    "Changer de société": "Changer de société",
    "Offre commerciale": "Offre commerciale",
    "Contrat de support": "Contrat de support",
    "Total dû depuis le": "Total dû depuis le",
    "Date d'échéance au": "Date d'échéance au",
    "Offre valide jusqu'au": "Offre valide jusqu'au",
    "Contrat arrivant à échéance le": "Contrat arrivant à échéance le",
    "Afficher la facture au format PDF": "Afficher la facture au format PDF",
    "Afficher le détail de l'offre": "Afficher le détail de l'offre",
    "Afficher le détail du contrat": "Afficher le détail du contrat",
    "Offre validée": "Offre validée",
    "Offre envoyée": "Offre envoyée",
    "Commande livrée": "Commande livrée",
    "Demandeur non défini": "Demandeur non défini",
    "Réglée": "Réglée",
    "Facture échue": "Facture échue",
    "A régler": "A régler",
    "En attente de livraison": "En attente de livraison",
    "Livré": "Livré",
    "Gamme inconnue": "Gamme inconnue",
    "Hors garantie": "Hors garantie",
    "Sous garantie": "Sous garantie",
    "Support expiré": "Support expiré",
    "Expiration dans moins de 3 mois": "Expiration dans moins de 3 mois",
    "En cours": "En cours",
    "Enlèvement sur site": "Enlèvement sur site",
    "Etude de couverture Wifi": "Etude de couverture Wifi",
    "Service de pré validation par un membre Stillnetwork après création du ticket": "Service de pré validation par un membre Stillnetwork après création du ticket",
    "Transmettez-nous votre configuration matériel": "Transmettez-nous votre configuration matériel",
    "Procédez à une sortie de stock et lancer l'expédtion de vos produits": "Procédez à une sortie de stock et lancer l'expédtion de vos produits",
    "Faites une demande de prestations d'ingénierie qualifié": "Faites une demande de prestations d'ingénierie qualifié",
    "Servide de facturation client": "Servide de facturation client",
    "Décommissionner du matériel": "Décommissionner du matériel",
    "Effectuer une demande d'analyse de la couverture, de la fiabilité et des performances de votre réseau sans fil": "Effectuer une demande d'analyse de la couverture, de la fiabilité et des performances de votre réseau sans fil",
    "Service spécifique": "Service spécifique",
    "Disponible": "Disponible",
    "Réapprovisionnement en cours": "Réapprovisionnement en cours",
    "Sans réapprovisionnement": "Sans réapprovisionnement",
    "Offre déclinée": "Offre déclinée",
    "Production en cours": "Production en cours",
    "Attention, les quantités doivent être des nombres entiers": "Attention, les quantités doivent être des nombres entiers",
    "Liste des équipements en stock": "Liste des équipements en stock",
    "Réinitialiser": "Réinitialiser",
    "Voulez vous vraiment réinitialiser ?": "Voulez vous vraiment réinitialiser ?",
    "Tout expédier": "Tout expédier",
}