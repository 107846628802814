
import { defineComponent, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n"
import { app } from "./main";
import * as moment from "moment"
import Swal from "sweetalert2/dist/sweetalert2.js";
import mAxiosApi from "@/api";

export let installPrompt
export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const i18n = useI18n();
    i18n.locale.value = "fr";
    const { t } = useI18n();
    let installPrompt: any = null
    
    const errorTrad = (text = "") => {
      console.log("Attention erreur 't' à la place de '$t' sur le text : " + text);
    }

    moment.default.locale("fr");


    app.config.globalProperties.$t = t;
    app.config.globalProperties.t = errorTrad;


    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        
        if (process.env.VUE_APP_BACK_URL == "https://rh.backend-stillnetwork.ovh/rhapi") {
          const appVersion = await mAxiosApi.prototype.getAxios("https://rh.backend-stillnetwork.ovh/version.php");
          const versionAppliPWA = document.getElementById('versionApp')?.innerHTML;
          if (versionAppliPWA && appVersion.trim() === versionAppliPWA.trim()) {
            // console.log("ok")
          } else {
            location.reload();
          }
        }
      }
    };


    onBeforeUnmount(async () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    });

    onMounted(async () => {
      const registration = await navigator.serviceWorker.register("/sw.js");

      document.addEventListener('visibilitychange', handleVisibilityChange);

      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        installPrompt = e;
      });
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent) && !window.matchMedia('(display-mode: standalone)').matches) {
        (window as any).AddToHomeScreenInstance = new (window as any).AddToHomeScreen(
          {
          appName: 'StillRH',                                   // Name of the app
          appIconUrl: 'ios/144.png',                       // App icon link (square, at least 40 x 40 pixels)
          assetUrl: 'add-to-homescreen/src/assets/img/',  // Link to directory of library image assets 
          showErrorMessageForUnsupportedBrowsers: true,          // Should we prompt users on non-compliant browsers (like IOS Firefox) to switch to compliant one (like Safari) Default: true.
          allowUserToCloseModal: false,                           // Allow user to close the 'Add to Homescreen' message? Not allowing will increase installs. Default: false.
          maxModalDisplayCount: -1                                // If set, the modal will only show this many times. Default is -1 (no limit).  (Debugging: Use this.clearModalDisplayCount() to reset the count)
          }
        );
        (window as any).AddToHomeScreenInstance.show();  
      }
      else if (/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && !window.matchMedia('(display-mode: standalone)').matches) {
        Swal.fire({
          text: "Ce site peut être installé sur votre appareil !",
          icon: "info",
          buttonsStyling: false,
          allowOutsideClick: false,
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: "Annuler",
          confirmButtonText: "Installer",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-secondary",
          },
        }).then(async (choice) => {
          if (choice.isConfirmed) {
            if (!installPrompt) {
              return;
            }
            installPrompt.prompt();
          }
        });
      }
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });
  },
});
